import React, { createContext, useContext, ReactNode, useState } from "react";

type PageType = string;

interface PageContextProps {
  pageType?: PageType;
  slug?: string;
  setPageType?: (pageType: PageType) => void;
  setSlug?: (slug: string) => void;
}

const PageContext = createContext<PageContextProps | undefined>(undefined);

export const PageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [pageType, setPageType] = useState<PageType>();
  const [slug, setSlug] = useState<string>();

  return (
    <PageContext.Provider value={{ pageType, slug, setPageType, setSlug }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = (): PageContextProps => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("usePageContext must be used within a PageProvider");
  }
  return context;
};
