import "keen-slider/keen-slider.min.css";
import "./src/static/styles/app.scss";
import "aos/dist/aos.css";
import React from "react";
import AOS from "aos";

// contexts.
import { PopupProvider } from "@/contexts/PopUp";
import { PageProvider } from "@/contexts/Page";

export const onClientEntry = () => {
  AOS.init({
    duration: 1200,
  });
};

export const onRouteUpdate = () => {
  AOS.refresh();
};

export const wrapRootElement = ({ element }) => (
  <PageProvider>
    <PopupProvider>{element}</PopupProvider>
  </PageProvider>
);
